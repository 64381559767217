.cdx-quote-icon svg {
  transform: rotate(180deg);
}

.cdx-quote {
  margin: 0;
}

.cdx-quote__text {
  min-height: 158px;
  margin-bottom: 10px;
}

.cdx-quote__caption, .cdx-quote__caption__extra  {
  margin-bottom: 10px;
}

.cdx-quote [contentEditable=true][data-placeholder]::before{
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.cdx-quote [contentEditable=true][data-placeholder]:empty::before {
  opacity: 1;
}

.cdx-quote [contentEditable=true][data-placeholder]:empty:focus::before {
  opacity: 0;
}


.cdx-quote-settings {
  display: flex;
}

.cdx-quote-settings .cdx-settings-button {
  width: 50%;
}
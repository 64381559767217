// .container{
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 80px 20px;

//   .logo{
//     display: block;
//     width: 100%;
//     max-width: 200px;
//     margin: 0 auto;
//   }
// }

.container{
  @apply w-full h-screen grid grid-cols-12 gap-0;

  .left{
    @apply col-span-12 md:col-span-6 bg-[#283348] flex justify-center items-center p-5 md:p-10 h-fit md:h-full;

    img{
      @apply max-w-[70px] md:max-w-[165px] block w-full;
    }
  }

  .right{
    @apply col-span-12 md:col-span-6 bg-white flex flex-col justify-between p-5 md:p-10 lg:p-16;
  }
}